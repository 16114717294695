@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    html {
        @apply text-rg;
    }

    body {
        @apply font-body leading-normal text-grayDark;
        visibility: hidden;
        opacity: 0;
        transition: opacity .25s;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply font-bold leading-tight;
        
    }

    h1 {
        @apply text-2xl lg:text-3xl;
    }

    h2 {
        @apply text-xl lg:text-2xl;
    }

    h3 {
        @apply text-lg lg:text-xl;
    }

    h4 {
        @apply text-rg lg:text-lg;
    }

    h5 {
        @apply text-lg;
    }

    h6 {
        @apply text-rg;
    }

    label {
        @apply inline-block mb-1;
    }
}