// Product Filter 
.filter_wrap_content {
    height: calc(100% - 80px);
    padding-bottom: 80px;
}
.filter_footer_bar {
    @apply absolute left-0 bottom-0 w-full h-19 flex justify-center items-center bg-white
}

// Filter Color & Checkbox

$color_1: #092e48;
$border-color_1: #00bc68;
$border-color_2: rgba(0, 0, 0, 0.2);

.group_filter {
	.list_filter {
		margin-left: -10px;
		margin-right: -10px;
		.item {
			width: 50%;
			margin-bottom: 24px;
			padding: 0 10px;
			.lb_filter {
				cursor: pointer;
				display: inline-block;
				font-size: 15px;
				line-height: 24px;
				color: $color_1;
				width: calc(100% - 24px);
				padding-left: 15px;
			}
			input[type="checkbox"] {
				float: left;
				border: 1px solid #D9D9D9;
				border-radius: 4px;
				cursor: pointer;
				height: 24px;
				width: 24px;
				margin: 0;
				appearance: none;
				-webkit-appearance: none;
				&:checked {
					background: url('../images/icons/sprite_layout.svg') no-repeat;
					background-position: -178px -61px;
					border: none;
				}
			}
			.box_color {
				max-width: 44px;
				height: 44px;
				margin: 0 auto;
				border-radius: 100%;
				border: 2px solid #fff;
				position: relative;
				.show_color {
					border-radius: 100%;
					max-width: 36px;
					height: 36px;
					margin: 2px auto;
					border: 1px solid #fff;
					overflow: hidden;
					img {
						display: block;
						width: 100%;
						height: auto;
					}
				}
			}
			.label_txt {
				font-size: 12px;
				line-height: 20px;
				color: $color_1;
				text-align: center;
				margin-top: 5px;
			}
		}
	}
	.list_filter.full {
		margin: 0;
		.item {
			width: 100%;
			padding: 0;
			.lb_filter {
				padding-left: 0;
				padding-right: 15px;
				float: left;
			}
			input[type="checkbox"] {
				float: none;
			}
		}
	}
	.list_filter.color {
		margin-left: -5px;
		margin-right: -5px;
		.item {
			width: 90px;
			padding: 0 5px;
			input {
				display: none;
				&:checked {
					~ {
						.label-color {
							.box_color {
								border-color: $border-color_1;
								&:before {
									content: "";
									position: absolute;
									bottom: 50%;
									right: 50%;
									width: 12px;
									height: 9px;
									background: url('../images/icons/sprite_products.svg') -24px -93px no-repeat;
									-webkit-transform: translate(50%, 50%);
									-moz-transform: translate(50%, 50%);
									-ms-transform: translate(50%, 50%);
									-o-transform: translate(50%, 50%);
									transform: translate(50%, 50%);
								}
							}
							.box_color.bg-light {
								&:before {
									background-position: -58px -93px;
								}
							}
						}
					}
				}
			}
			.label-color {
				display: block;
				cursor: pointer;
				.box_color.bg-light {
					.show_color {
						border-color: $border-color_2;
					}
				}
			}
		}
	}
}



// Filter Checboxes
// ul.ks-cboxtags {
//     list-style: none;
// }
// ul.ks-cboxtags li{
//   display: inline;

// }
// ul.ks-cboxtags li label{
//     display: inline-block;
//     background-color: rgba(255, 255, 255, .9);
//     border: 2px solid rgba(139, 139, 139, .3);
//     color: #adadad;
//     border-radius: 25px;
//     white-space: nowrap;
//     margin: 6px 0px;
//     -webkit-touch-callout: none;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     -webkit-tap-highlight-color: transparent;
//     transition: all .2s;
// }

// ul.ks-cboxtags li label {
//     padding: 8px 12px;
//     cursor: pointer;
// }

// ul.ks-cboxtags li label::before {
//     display: inline-block;
//     font-style: normal;
//     font-variant: normal;
//     text-rendering: auto;
//     -webkit-font-smoothing: antialiased;
//     font-family: "Font Awesome 6 Pro";
//     font-weight: 900;
//     font-size: 12px;
//     padding: 2px 6px 2px 2px;
//     content: "\f067";
//     transition: transform .3s ease-in-out;
// }

// ul.ks-cboxtags li input[type="checkbox"]:checked + label::before {
//     content: "\f00c";
//     transform: rotate(-360deg);
//     transition: transform .3s ease-in-out;
// }

// ul.ks-cboxtags li input[type="checkbox"]:checked + label {
//     border: 2px solid #1bdbf8;
//     background-color: #A7479A;
//     color: #fff;
//     transition: all .2s;
// }

// ul.ks-cboxtags li input[type="checkbox"] {
//   position: absolute;
//   opacity: 0;
// }
// ul.ks-cboxtags li input[type="checkbox"]:focus + label {
//   border: 2px solid #e9a1ff;
// }