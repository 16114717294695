// FOOTER
footer {
    @include media-breakpoint-down(lg) {
        @apply pb-[50px];
    }
    .f-navbar {
        @apply flex flex-col gap-y-1 lg:gap-y-4;
        .f-nav-link {
            @apply text-sm;
        }
    }

    .f-socials {
        @apply flex flex-row flex-wrap items-center gap-5 mt-4 lg:mt-10;
        a {
            svg {
                @apply fill-greenLight h-6;
            }
            &:hover {
                svg {
                    @apply fill-grayDark;
                }
            }
        }
    }
}

.float-widget {
    @include media-breakpoint-down(lg) {
        @apply fixed left-0 right-0 bottom-0 z-50 bg-green py-2;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .widget-wrapper { 
            @apply flex flex-row gap-x-1 lg:gap-x-5 justify-center items-center;

            .widget-ico-box {
                @apply flex flex-col gap-y-1 items-center text-center;

                .widget-ico {
                    @apply w-5 flex flex-none justify-center;
                    img {
                        @apply h-5;
                    }
                    &.whatsapp {
                        @apply border-none;
                    }
        
                }
                .widget-name {
                    @apply  bg-white text-[10px] font-light text-greenLight whitespace-nowrap rounded-xl py-[2px] px-2;
                }

            }
        }
    }
    

    @screen lg {
        @apply fixed right-2 bottom-15 z-50;

        .widget-wrapper {
            @apply flex flex-col gap-y-3;
            .widget-ico-box {
                @apply relative;
    
                .widget-ico {
                    @apply w-14 h-14 bg-greenLight border border-white/30 rounded-full overflow-hidden flex justify-center items-center;
        
                    &.whatsapp {
                        @apply bg-transparent border-none;
                    }
        
                }
                .widget-name {
                    @apply absolute -z-[1] top-1/2 -right-[140px] -translate-y-1/2 bg-purple text-rg font-bold text-white whitespace-nowrap rounded-xl py-2 px-3 transition-all duration-500 ease-linear;
                }
    
                &:hover {
                    .widget-name {
                        @apply right-15;
                    }
                }
            }
        }
    }
}