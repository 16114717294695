.swiper {
    //width: 100%;
    //height: 100%;
    &.hero-banner {
        height: 47.78dvw;
        position: relative;
        // @include media-breakpoint-up(lg) {
        //     height: 49.78dvw;
        // }

        .swiper-pagination {
            position: relative;
            bottom: unset;
            top: unset;
            left: unset;
            width: auto;

            .swiper-pagination-bullet {
                @include media-breakpoint-down(sm) {
                    width: 8px;
                    height: 8px;
                    background-color: #DBDBDB;
                }

                &.swiper-pagination-bullet-active {
                    @include media-breakpoint-down(sm) {
                        width: 9px;
                        height: 9px;
                        background-color: #0EA94E;
                    }
                }
            }
        }
    }
    
    .arrow-nav {
        width: 48px;
        height: 48px;
        border-radius: 24px;
        justify-content: center;
        align-items: center;
        background-color: #0EA94E;
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: #A7479A;
        }

        &.nav-prev {
            width: 48px;
            height: 48px;
            //background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='18' viewBox='0 0 10 18' fill='none'%3E%3Cpath d='M8.75 16.5L1.25 9L8.75 1.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            @screen sm {
                width: 16px;
                height: 16px;
                background-size: 4px;
            }
            @screen md {
                width: 24px;
                height: 24px;
                background-size: 8px;
            }
        }

        &.nav-next {
            width: 48px;
            height: 48px;
            //background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='18' viewBox='0 0 10 18' fill='none'%3E%3Cpath d='M1.25 1.5L8.75 9L1.25 16.5' stroke='%23FFFFFF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            @screen sm {
                width: 16px;
                height: 16px;
                background-size: 4px;
            }
            @screen md {
                width: 24px;
                height: 24px;
                background-size: 8px;
            }
        }
    }

    &.produk-aplikasi {
        width: 90%;
        .swiper-pagination {
            position: relative;
            top: unset;
            bottom: unset;
            margin-top: 20px;

            .swiper-pagination-bullet {
                background-color: #DBDBDB;

                &.swiper-pagination-bullet-active {
                    background-color: #0EA94E;
                }
            }
        }
        
    }

    &.proyek {
        //margin-left: 7%;
        .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .3s ease-in-out;

            &.swiper-slide-active {
                width: 40% !important;
                @include media-breakpoint-down(sm) {
                    width: 90% !important;
                }
                //@apply !w-[40%] sm:w-auto;

                .description {
                    display: block;
                    opacity: 1;
                }

                .card {
                    &.proyek {
                        .card-body {
                            @apply p-8;
                        }
                        .title {
                            @apply text-2xl mb-4 leading-none;
                        }
                    }
                }
            }
        }

        .swiper-button-next, .swiper-button-prev {
            background-color: #ffffff;
            color: #0EA94E;
        
            &:hover {
                background-color: #A7479A;
                color: white;
            }
        }
    }

}

.nav-wrapper-produk-aplikasi {
    @apply absolute -translate-y-1/2 w-full z-10;
    top: calc(50% - 20px);

    .swiper-button-prev, .swiper-button-next {
        //top: calc(50% - 20px);
        &:after {
            content: "";
            width: 48px;
            height: 48px;
            font-size: 0;
            background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
    .swiper-button-prev {
        &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M19.5 12L4.5 12M4.5 12L11.25 18.75M4.5 12L11.25 5.25' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
        
    }
    .swiper-button-next {
        &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.5 12L19.5 12M19.5 12L12.75 5.25M19.5 12L12.75 18.75' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
    }
}

.nav-wrapper-proyek {
    @apply absolute -translate-y-1/2 w-full z-10;
    top: 50%;

    .swiper-button-next, .swiper-button-prev {
            
        background-color: #ffffff;
        color: #0EA94E;
    
        &:hover {
            background-color: #A7479A;
            color: white;
        }
    }
}

.swiper-pagination {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    .swiper-pagination-bullet {
        background: #ffffff;
        opacity: 1;
        &.swiper-pagination-bullet-active {
            width: 18px;
            height: 18px;
            background: #0EA94E;
        }
    }
}

.swiper-button-next, .swiper-button-prev {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: #0EA94E;
    color: #ffffff;
    transition: all 0.3s ease-in-out;

    &:after {
        font-size: 20px;
    }

    &:hover {
        background-color: #A7479A;
    }
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.produkSlider {
    @apply relative overflow-hidden mb-10;
    .swiper-slide {
        height: 75vh;
        max-height: 75vh;
    }
}