.accordion {

    &-item {

        &:not(:first-of-type) {
            @apply border-t-0;
        }
    }

    &-toggle {

        &[aria-expanded=true] {
            background-color: inherit;
            transition-duration: .75s;
            transition-property: background-color, border-color;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            .icon {

                svg {
                    @apply rotate-180;
                }
            }
        }

        &[aria-expanded=false] {
            transition-duration: .75s;
            transition-property: background-color, border-color;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .icon {

            svg {
                @apply h-4 fill-black;
                transition-duration: .5s;
                transition-property: transform;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }

    &-content {
        transition-duration: .5s;
        transition-property: transform, opacity;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &[aria-hidden=true] {
            height: 0;
            opacity: 0;
            transform: translate3d(0, -0.5rem, 0);
            visibility: hidden;
        }

        &[aria-hidden=false] {
            height: auto;
            opacity: 1;
            transform: translate3d(0, 0, 0);
            visibility: visible;
        }
    }

    &--product-info {
        .accordion-item {
            @apply border-none;
        }
    }

    
}
.hs-accordion-group {
    //@apply columns-1 lg:columns-2 lg:gap-x-7;

    &.for-filter {
        //@apply columns-1;

        .hs-accordion {
            @apply py-1 px-4 mb-0 h-auto w-full overflow-hidden transition-[height] duration-300 rounded-none shadow-none;

            button {
                @apply text-[#092E48] py-5 border-b border-grayLight rounded-none inline-flex items-center justify-between gap-x-3 w-full font-semi text-start disabled:opacity-50 disabled:pointer-events-none;
            }

            .hs-accordion-content {
                @apply mt-5;
            }
        }
    }   
}
.hs-accordion {
    @apply p-3 mb-5 h-max rounded-xl shadow-md;
    break-inside:avoid;
    
    button {
        @apply  text-greenLight py-3 inline-flex justify-between items-center gap-x-3 w-full font-semi text-start hover:text-greenDark disabled:opacity-50 disabled:pointer-events-none;
    }
    
    .question {
        @apply flex flex-row gap-2;
        .q_letter {
            @apply w-[40px] text-xl;
        }
        .q_text {
            @apply w-full pt-2 font-semi;
        }
    }

    .hs-accordion-content {
        @apply  w-full overflow-hidden transition-[height] duration-300;
    }
    .answer {
        @apply flex flex-row gap-2;
        .a_letter {
            @apply w-[40px] font-bold text-xl;
        }
        .a_text {
            @apply w-full;
        }

        ol {
            list-style-type: decimal;
            padding-left: 16px;
        }
    }
    .plus-minus {
        @apply border border-greenLight rounded-full p-2;
    }
}

// Product Details Accordion


