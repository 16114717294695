.form {

    &-group {
        @apply mb-4;
    }

    &-input, &-textarea, &-select {
        @apply text-grayDark border border-[#DBDBDB] w-full px-3 py-2 transition ease-in-out focus:border-greenLight focus:ring-greenDark #{!important};
    }
}
