// NEWS
.card-news {
    @apply flex flex-col gap-y-3;

    &:hover {
        .news-photo {
            img {
                @apply scale-110;
            }
        }
        .btn {
            background-color: #A7479A !important;
        }
    }

    h3 {
        @apply lg:text-[22px] line-clamp-1 mb-2;
    }

    .news-date {
        @apply flex items-center gap-x-2 mb-3;

        p {
            @apply text-sm;
        }
    }

    .news-photo {
        @apply w-full rounded-xl overflow-hidden mb-4 h-[200px] max-h-[200px] lg:h-[350px] lg:max-h-[350px];

        img {
            @apply w-full h-full object-cover object-center scale-100 transition-transform duration-500 ease-in-out;
        }
    }

    .news-excerpt {
        @apply mb-2 line-clamp-5;
    }

    .btn {
        @apply transition-all duration-500 ease-in-out;
    }
}