/** PRODUCTS **/
.modal-products {
    max-width: 1140px;

    .products-thumbs {
        @apply w-full;
        img {
            @apply w-full object-cover object-top;
            max-width: unset;
        }
    }
    .products-img {
        @apply  mb-10;
        img {
            @apply w-full object-cover object-top;
            max-width: unset;
        }
    }
}

.modal-proyek {
    max-width: 1140px;

    .proyek-img {
        @apply  mb-10;
        img {
            @apply w-full object-cover object-top;
            max-width: unset;
        }
    }
}

.prod-head-img {
    @apply h-[25vh] mb-10;
    @screen md {
        @apply h-[23vh];
    }
    @screen lg {
        @apply h-[50vh] pt-19 mb-18;
    }
    img {
        @apply w-full h-full object-cover object-center;
    }
}
.prod-idx-grid {
    @apply w-full flex flex-wrap justify-center gap-2 my-10 mx-auto;
    @screen lg {
        @apply gap-5 w-4/5;
    }
}
.prod-idx-card {
    @apply relative w-[26.9dvw] h-auto;
    @screen md {
        @apply w-[17.1dvw];
    }
    @screen lg {
        @apply w-[170px];
    }
    &:hover {
        .prod-img {
            img {
                @apply scale-125;
            }
        }
    }

    &.in-page {
        &:after {
            border-bottom: 14px solid #27b461;
            border-left: 13px solid #fff;
            border-right: 13px solid #fff;
            bottom: -40px;
            content: "";
            display: inline-block;
            position: absolute;
            right: 50%;
            transform: translateX(50%);
        }
    }
    .prod-img {
        @apply rounded-lg border-4 border-graySemi overflow-hidden;
        img {
            @apply w-full h-full object-cover object-center scale-100 transition-all duration-500 ease-linear;
        }
    }
}
.prod-idx-name {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    color: #fff;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    padding: 5px;
    background-color: rgba($color: #2E2E2E, $alpha: 0.5);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
    @screen md {
        @apply text-xs;
    }
    @screen lg {
        @apply text-rg;
        padding: 10px 5px;
    }
}
.prod-idx-usp {
    background: rgb(0,105,27);
    background: linear-gradient(90deg, rgba(0,105,27,1) 0%, rgba(0,171,79,1) 50%, rgba(0,171,79,1) 100%); 
    background-image: url('../images/world-map.png');;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -390px -290px;
    filter: blur(0.6px);
}
.prod-usp-card {
    @apply flex flex-col items-center;
    .usp-ico {
        @apply flex justify-center items-center p-[18px] w-[100px] h-[100px] border-[6px] border-white rounded-full mb-2;
        @screen lg {
            @apply w-[120px] h-[120px];
        }
    }
    .usp-txt {
        @apply text-white text-rg text-center font-semi;
        @screen lg {
            @apply text-lg;
        }
    }
}

// PRODUCT TYPE
.prod-type-grid {
    @apply pb-10;
    
    .swiper-wrapper {
        @screen md {
            justify-content: center;
        }
        @screen lg {
            justify-content: center;
        }
        .swiper-slide {
            width: 170px !important;
        
            // &.swiper-slide-active {
            //     &:after {
            //         border-bottom: 14px solid #27b461;
            //         border-left: 13px solid #fff;
            //         border-right: 13px solid #fff;
            //         bottom: -40px;
            //         content: "";
            //         display: inline-block;
            //         position: absolute;
            //         right: 50%;
            //         transform: translateX(50%);
            //     }
            // }

            &.current {
                &:after {
                    border-bottom: 14px solid #27b461;
                    border-left: 13px solid #fff;
                    border-right: 13px solid #fff;
                    bottom: -40px;
                    content: "";
                    display: inline-block;
                    position: absolute;
                    right: 50%;
                    transform: translateX(50%);
                }
            }
        }
    }
    .prod-type-card {
        position: relative;
        display: inherit;
        &:hover {
            .prod-type-img {
                img {
                    @apply scale-125;
                }
            }
        }
        .prod-type-img {
            @apply rounded-lg border-4 border-graySemi overflow-hidden;
            img {
                @apply w-full h-full object-cover object-center scale-100 transition-all duration-500 ease-linear;
            }
        }
        .prod-type-name {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 5;
            color: #fff;
            text-align: center;
            font-size: 10px;
            text-transform: uppercase;
            padding: 5px;
            background-color: rgba($color: #2E2E2E, $alpha: 0.5);
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            overflow: hidden;
            @screen md {
                @apply text-xs;
            }
            @screen lg {
                @apply text-rg;
                padding: 10px 5px;
            }
        }
    }
}


// PRODUCT DETAILS
.prod-dtl-header {
    //@apply relative flex flex-col-reverse lg:block;

    &__info-desc {
        @apply mb-10;
        
        @screen lg {
            @apply -mt-13;
        }

        h6 {
            @apply text-xs text-[#00AD57] font-normal mb-5;
        }
        h1 {
            @apply text-2xl mb-5;
        }


    }

    &__list {
        @apply flex-col;
        .col {
            @apply pt-3 leading-none;
            &.has-border {
                @apply pb-3 border border-b-gray border-t-0 border-x-0;
            }
        }
    }

    
}

.banner_prod {
    @apply flex flex-row-reverse flex-wrap;
    @screen sm {
        @apply block;
    }

    .col-banner {
        @apply w-1/2;
        @screen sm {
            @apply w-full;
        }
        .wrap-slide {
            @apply pl-10;
            @screen sm {
                @apply pl-0;
            }
            .slide-banner {
                @apply relative block;
                .slide-picture {
                    @apply relative block;
                    transform: translate3d(0,0,0);
                    .prod-logo {
                        @apply absolute left-2 top-8;
                    }
                    picture {
                        @apply float-right w-full h-full min-h-[1px];
                        .img-slide {
                            @apply block w-full h-auto;
                            img {
                                @apply w-full h-auto object-cover object-center;
                            }
                        }
                    }
                }
                
            }
        }

        .content-banner {
            padding: 80px 20px 60px;
            max-width: 580px;
            margin-left: auto;
            @screen sm {
                @apply pb-5;
            }
            @screen md {
                @apply pt-8;
            }
        }
    }
}

.prod-info-accdn {
    @apply mb-10;

    h3 {
        position: relative;
        color: #0EA94E;
        text-align: left;
        border-top: 1px solid #898A8D;
        padding-top: 20px;
        padding-bottom: 20px;
        cursor: pointer;
        padding-right: 60px;
        transition: 0.25s all;

        &.alignLeft {
            padding-left: 35px;
        }
    }

    .prod-info-cnt {
        display: none;
        padding: 10px 0 30px;
        margin-bottom: 10px;

        .certificate {
            .dsc-img-wrap {
                @apply flex gap-x-2;
                .cert-img {
                    @apply w-2/12; 
                    img {
                        @apply w-full;
                    }
                }
                .cert-dsc {
                    @apply w-10/12;
                }
            }
            
        }
    }

    .collapseIcon {
        position: absolute;
        right: 20px;
        top: 48%;
        font-size: 25px;
        font-weight: 300;
        color: white;
        transform: translate(0, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        //border: 1px solid #0EA94E;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background-color: #0EA94E;
    }
    
}

.prod-info-features {
    //@apply flex flex-wrap;

    .features-item {
        @apply flex gap-x-2 items-center text-graySemi my-2;
    }
}
.color-item {
    @apply flex items-center;
    .prod-color {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        max-width: 44px;
        height: 44px;
        border-radius: 100%;
        position: relative;
        padding-top: 0 !important;
        .box-color {
            border-radius: 100%;
            width: 36px;
            max-width: 36px;
            height: 36px;
            overflow: hidden;
            padding-top: 0 !important;
            text-align: center;
            &.checked {
                @apply outline outline-1 outline-offset-4 outline-[#ccc];
                &:after {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    content: "\f00c";
                    font-family: "Font Awesome 6 Pro";
                    font-weight: 900;
                    font-size: 14px;
                    color: #FFF;
                }
            }
            &.shine-light-red-oak {
                background-color: #CC6B49;
            }
            &.midnight-grey {
                background-color: #828282;
            }
            &.light-grey {
                background-color: #D3D3D3;
            }
            &.shine-light-oak {
                background-color: #977663;
            }
            &.shine-light-black-teak {
                background-color: #7C451C;
            }
            &.red-makna {
                background-color: #A13935;
            }
            &.beech {
                background-color: #D6B08B;
            }
            &.grey-modren {
                background-color: #979AA1;
            }
            &.red-mahogany {
                background-color: #844746;
            }
            &.cherry-red {
                background-color: #711B11;
            }
            &.uncolored {
                background-color: #ffffff;
            }
            &.brown-chesnut {
                background-color: #A68B6C;
            }
        }
    }
    .color-txt {
        @apply text-xs text-graySemi ml-2;
        padding-bottom: 0 !important;
        border-bottom: none;
    }
}

.profiling-item {
    @apply flex items-center;
    .prod-profile {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        max-width: 44px;
        height: 44px;
        border-radius: 100%;
        //border: 1px solid #CCC;
        position: relative;
        padding-top: 0 !important;
        .box-profile {
            border-radius: 100%;
            width: 36px;
            max-width: 36px;
            height: 36px;
            overflow: hidden;
            padding-top: 0 !important;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            &.checked {
                @apply outline outline-1 outline-offset-2 outline-[#ccc];
                &:after {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    content: "\f00c";
                    font-family: "Font Awesome 6 Pro";
                    font-weight: 900;
                    font-size: 12px;
                    color: #FFF;
                }
            }
        }
    }
    .profile-txt {
        @apply text-xs text-graySemi ml-2;
        padding-bottom: 0 !important;
        border-bottom: none;
    }
}
