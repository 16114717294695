/** PARALLAX **/
#tentangKami {
    @screen lg {
        @apply relative bg-white z-10;
    }
}
// .mm_about {
//     &--image {
//         @screen lg {
//             @apply mt-10;
//         }  
//     }

//     &--text {
//         @screen lg {
//             margin-top: -800px !important;
//             @apply opacity-0;
//         }
//     }
// }

.mm_product {
    // &--card {
    //     @screen lg {
    //         margin-top: 60px;
    //         opacity: 0;
    //     }
    // }
    &--card {
        @screen lg {
            //@apply -top-[600px]
        }
    }
}

.mm_catalog {
    // &--text {
    //     @screen lg {
    //         position: relative;
    //         top: -150px;
    //         //z-index: -1;
    //     }
    // }
    &--image {
        @screen lg {
            position: relative;
            top: -480px;
            z-index: -1;
        }
    }
}