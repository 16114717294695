@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";

// __________________ VENDOR
@import "./vendors/bootstrap";
@import '~swiper/css/bundle';
@import "~aos/src/sass/aos";
//@import "~nice-select2/src/scss/nice-select2";
@import "~nice-select2/dist/css/nice-select2";
@import "@fancyapps/ui/dist/fancybox/fancybox.css";

// __________________ COMPONENTS
//@import "./components/fonts";
@import "./components/utilities";
@import "./components/buttons";
@import "./components/form";
@import "./components/navbar";
@import "./components/footer";
@import "./components/accordion";
//@import "./components/tab";
@import "./components/modal";
@import "./components/lazyload";
@import "./components/searchbox";
@import "./components/hamburger";
@import "./components/parallax";
@import "./components/typo";
@import "./components/plugins";
@import "./components/content";
@import "./components/post";
@import "./components/wrapper";
@import "./components/carousel";
@import "./components/card";

@import "./components/filter";

// __________________ PAGES
@import "./pages/404";
@import "./pages/news";
@import "./pages/products";