.page {
    &-headColor {
        @apply mt-[48px] lg:mt-19 h-[150px] min-h-[150px];
    }
}

.section-border-bottom {
    @apply border-b border-b-[#707070] pb-8 mb-10 lg:pb-12;

    p {
        @apply mb-10;
        &:last-child {
            @apply mb-0;
        }
    }
}

/** HERO SLIDER **/
.banner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
    }

    .content-wrapper {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: auto;
        padding: 1rem;
        transition: all 0.2s ease-in-out;

        @include media-breakpoint-down(sm) {
            position: relative;
        }
    }

    .image-wrapper {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: auto;
        display: block;
        // @include media-breakpoint-up(lg) {
        //     height: 100vh;
        // }
        
        .image {
            width: 100%;
            display: block;
            object-fit: cover;
            object-position: center;
        }
    }
}

.basic-intro {
    position: relative;
    z-index: 1;
    width: 100%;
    //margin-left: auto;
    margin-right: auto;
    padding: 40px 60px;
    border-radius: 20px;
    overflow: hidden;
    will-change: transform;
    background-color: rgba(255,255,255, .55);
    backdrop-filter: blur(5px);

    @include media-breakpoint-down(lg) {
        margin-bottom: 10px;
        padding: 10px;
    }
    @include media-breakpoint-down(sm) {
        margin-top: -70px;
        margin-bottom: 50px;
    }

    h1 {
        color: #0EA94E;
        font-size: 48px;
        @include media-breakpoint-down(lg) {
            font-size: 28px;
        }
    }
    .description {
        margin:1.5rem 0;
        @include media-breakpoint-down(lg) {
            margin:.5rem 0;
        }
    }
}

/** DOWNLOAD **/
.download-list {
    @apply grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-7 gap-y-5 lg:gap-y-15;
    
}
.download-card {
    @apply w-full shadow-lg flex flex-col items-center justify-between text-center p-5;
    .dl-img {
        @apply max-h-[156px] mb-2;
        img {
            @apply w-full h-full object-cover object-center;
        }
    }
    h5 {
        @apply uppercase sm:text-xs text-rg line-clamp-2 mb-2;
    }
    p {
        @apply text-greenLight uppercase sm:text-[10px] text-xs text-center mb-2;
    }
    .btn {
        @apply sm:h-10 sm:text-sm items-center gap-x-2 hover:bg-purple hover:text-white hover:border-purple;
    }
}