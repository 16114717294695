.card {
	position: relative;
	z-index: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	word-wrap: break-word;
	background-color: transparent;
	background-clip: border-box;
	border-radius: 24px;
    overflow: hidden;
    will-change: transform;
	transition: all 0.3s ease;

    .card-body {
        position: relative;
        z-index: 1;
        display: flex;
        flex: 1 1 auto;
        padding: 1.75rem;
    }

    &.produk {
        height: 495px;
        //height: 345px;
        overflow: hidden;
        /*
        @include media-breakpoint-only(md) {
            height: 325px;
        }
        @include media-breakpoint-down(sm) {
            height: 445px;
        }
        */
        .badge {
            @apply inline-flex items-center gap-x-1 py-1 px-3 mb-4 rounded-full text-sm uppercase font-bold bg-greenLight text-white;
        }

        .title {
            color: #ffffff;
            margin-bottom: 16px;
            @apply line-clamp-2;
        }

        .description {
            color: #ffffff;
            margin-bottom: 30px;
            @apply line-clamp-3;
        }
    }

    &.rb-produk {
        //height: 495px;
        overflow: hidden;
        cursor: pointer;

        @include media-breakpoint-down(lg) {
            height: unset;
        }

        &:hover {
            .image-wrapper {
                img {
                    transform: scale(1.1);
                }
            }
        }
        .image-wrapper {
            position: relative;
            max-height: 500px;
            img {
                transform: scale(1);
                transition: all 0.3s ease;
            }
        }
        .card-body {
            padding: 0;
            .holder {
                width: 100%;
                background-color: #0EA94E;
                text-align: center;
                padding: 20px 10px;
                @include media-breakpoint-down(sm) {
                    padding: 10px;
                }
            }
            .title {
                color: #ffffff;
                margin-bottom: 5px;
                @apply text-lg uppercase line-clamp-2;
                @include media-breakpoint-down(sm) {
                    @apply text-rg;
                }
            }
            .btn {
                @apply bg-purple uppercase;
                @include media-breakpoint-down(sm) {
                    @apply text-xs font-normal px-2;
                }
                &:hover {
                    background-color: #087A37 !important;
                    border-color: #087A37 !important;
                }
            }
        }
    }

    &.proyek {
        @apply h-[490px];

        &:hover {
            .image-wrapper {
                img {
                    transform: scale(1.1);
                }
            }
        }
        a {
            @apply h-full flex;
        }
        .image-wrapper {
            overflow: hidden;
        }
        .badge {
            @apply inline-flex items-center gap-x-1 py-1 px-3 mb-4 rounded-full text-sm uppercase font-bold bg-greenLight text-white;
        }
        .card-body {
            @apply p-4;
        }
        .title {
            @apply text-white text-lg mb-3 leading-tight;
        }

        .description {
            @apply hidden text-white opacity-0 transition-all duration-300 ease-in-out;
        }

        .overlay {
            background-color: #000000;
            background: linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.35) 30%, rgba(0,0,0,0) 100%);
        }
    }
}

.product-grid {
    padding: 40px 20px;
    @screen sm {
        > * {
            @apply px-[5px];
        }
    }
    
    .card_prod-layer4 {
        position: relative;
        display: block;
        border-radius: 18px;
        box-shadow: 0 6px 20px 0 rgba(0,0,0,0.08);
        position: relative;
        background-color: white;
        margin-bottom: 20px;
        overflow: hidden;
        .logo {
            @apply absolute left-[6px] top-[10px] sm:w-[70px] w-[80px] z-10;
        }
        .box-picture {
            @apply block max-h-[200px] overflow-hidden;
            img {
                @apply w-full h-auto max-h-[200px] object-cover object-center scale-100 transition-all duration-500 ease-in-out;
            }

            &:hover {
                img {
                    @apply scale-110;
                }
            }
        }
        .box-txt {
            padding: 12px 14px 14px 14px;

            .txt-cat-sub {
                color: #00BC68;
                font-size: 12px;
                line-height: 16px;
                display: block;
                display: -webkit-box;
                max-height: 16px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                min-height: 16px;
            }
            .txt-dis {
                color: #092E48;
                font-size: 14px;
                line-height: 22px;
                margin-top: 6px;
                display: block;
                display: -webkit-box;
                max-height: 44px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                min-height: 44px;
            }
            .txt-size {
                color: #BBBBBB;
                font-size: 12px;
                line-height: 20px;
                display: block;
                display: -webkit-box;
                max-height: 20px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                min-height: 20px;
            }
            .box-social {
                margin-top: 12px;
                padding-top: 12px;
                border-top: 1px solid #F3F3F5;
                text-align: center;
                .txt-social {
                    display: inline-block;
                    font-size: 11px;
                    color: #959595;
                    margin-right: 5px;
                    cursor: pointer;
                    position: relative;
                }
            }
        }
    }
}


