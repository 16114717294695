$primary: #0EA94E;
$primary-invert: lighten($primary, 100%);

$secondary: #2E2E2E;
$secondary-invert: darken($secondary, 100%);

$tertiary: #A7479A;
$tertiary-invert: darken($tertiary, 100%);

$light: #fff;
$light-invert: darken($light, 100%);

$colors: (
    "primary": ($primary, $primary-invert),
    "secondary": ($secondary, $secondary-invert),
    "tertiary": ($tertiary, $tertiary-invert),
    "light": ($light, $light-invert),
);

.btn {
    @apply inline-flex items-center justify-center h-15 px-6 border border-transparent rounded-[24px] font-bold;
    text-align: center;
    outline: 0;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &-sm {
        @apply py-1 text-sm;
        height: unset;
    }

    &-primary {
        //background-color: #0EA94E;
        &:hover {
            background-color: #A7479A !important;
            border-color: #A7479A;
        }
    }

    &-white {
        background-color: #FFFFFF;
        color: $primary;
        &:hover {
            color: $light;
            background-color: #A7479A !important;
            border-color: #A7479A;
        }
    }

    svg {
        @apply h-4;
    }

    @each $name, $pair in $colors {
        $color: nth($pair, 1);
        $color-invert: nth($pair, 2);
        
        &-#{$name} {
            color: $color-invert;
            background-color: $color;
            //border-color: lighten($color: $color, $amount: 0%);

            svg {
                fill: $color-invert;
            }
        }
    }
    
    @each $name, $pair in $colors {
        $color: nth($pair, 1);
        $color-invert: nth($pair, 2);

        &-outline-#{$name} {
            background-color: transparent;
            border-color: $color;
            color: $color;

            svg {
                fill: $color;
            }
        }
    }
}

