/** UTILITIES **/
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 2.5px;
}
::-webkit-scrollbar-thumb {
    cursor: pointer;
	border-radius: 2px;
    background: #A7479A;
}


// SCROLLBAR FIREFOX
:root{
	scrollbar-color: #F8F8F8 #A7479A;
	scrollbar-width: 5px !important;
}

// 5 Columns 
@media (min-width: 992px) {
    .col-md-1-5 { width: 20%; }
    .col-md-2-5 { width: 40%; }
    .col-md-3-5 { width: 60%; }
    .col-md-4-5 { width: 80%; }
    .col-md-5-5 { width: 100%; }
}
  
  @media (min-width: 1200px) {
    .col-lg-1-5 { width: 20%; }
    .col-lg-2-5 { width: 40%; }
    .col-lg-3-5 { width: 60%; }
    .col-lg-4-5 { width: 80%; }
    .col-lg-5-5 { width: 100%; }
}

.header-page {
    @apply relative w-full h-[150px] max-h-[150px] md:h-[190px] md:max-h-[190px] lg:h-[340px] lg:max-h-[340px] overflow-hidden;
    .header-img {
        @apply absolute inset-0 w-full h-full;
        img {
            @apply w-full h-full object-cover object-center;
        }
        &:after {
            @apply absolute inset-0 content-[''] block bg-black/60;
        }
    }
    .header-txt {
        @apply absolute z-10 inset-0 flex justify-center items-center;
        .txt-wrapper {
            @apply text-white lg:text-[64px] font-bold uppercase;
            @screen md {
                font-size: 5dvw;
            }
            @screen sm {
                font-size: 7dvw;
            }
        }
    }
}

.align-v-bottom {
    @apply flex items-end;
}
.bg-grad-green {
    background: rgb(0,105,27);
    background: linear-gradient(90deg, rgba(0,105,27,1) 0%, rgba(0,171,79,1) 50%, rgba(0,171,79,1) 100%); 
}
.image-wrapper {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;
        transition: all 0.4s ease-out 0s;
        transform: scale(1);
    }
}

.overlay {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    background: linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.4) 60%, rgba(0,0,0,0) 100%);
    transition: all 0.2s ease-in-out;
    opacity: 1;
}

.holder {
    position: relative;
    width: 100%;
}

.rb-pagination {
    @apply p-4 flex justify-center items-center flex-wrap;

    .nav-prev {
        @apply h-10 px-5 text-green bg-greenLight transition-colors duration-150 rounded-l-lg;
        &:hover {
            @apply text-white bg-purple;
        }
    }
    .nav-next {
        @apply h-10 px-5 text-white bg-greenLight transition-colors duration-150 rounded-r-lg;
        &:hover {
            @apply bg-purple;
        }
    }
    .nav-page {
        @apply h-10 px-5 text-green bg-greenDark/10 transition-colors duration-150 hover:text-white hover:bg-greenDark;

        &.current {
            @apply h-10 px-5 text-white transition-colors duration-150 bg-purple hover:bg-greenDark;
        }
    }
}

.end-0 {
    inset-inline-end: 0px;  
}
.bg-gray-900 {
    background-color: rgb(17, 24, 39);
    opacity: 0.5;
}
