.post {
    @apply mt-[48px] pt-13 lg:mt-19;
    &-title {
        @apply mb-4;
    }
    &-date {
        @apply flex items-center gap-x-1 text-sm;
    }
    &-share {
        @apply flex items-center gap-x-3;
        .sm-icons {
            @apply flex items-center gap-x-3;

            svg {
                @apply fill-greenLight;
            }
        }
    }
    &-image {
        @apply max-h-[725px] rounded-lg lg:rounded-xl overflow-hidden will-change-transform my-8;
        img {
            @apply w-full h-full object-cover object-center;
        }
    }
    &-detail {
        @apply prose-headings:mb-2 prose-p:mb-4 prose-a:text-green prose-a:font-bold prose-ul:mb-4 prose-ol:mb-4 prose-ul:pl-4 prose-ol:pl-4 prose-ul:list-disc prose-ol:list-decimal;

        p {

            &:last-child {
                margin-bottom: 0 #{!important};
            }
        }
    }
}